<template>
  <div class="">
    <p class="section-title text-primary-100 font-medium">預約結帳資訊</p>
    <el-form label-position="left" label-width="150px">
      <el-form-item label="付款狀態">
        <div class="inline-flex items-center gap-[8px]">
          <Tag style="display: inline-flex" :type="displayData.status.tagType" disable-transitions>
            {{ displayData.status.name }}
          </Tag>
          <el-button
            v-if="showRefundBtn"
            type="text"
            class="refund-btn underline"
            @click="modal.refund = true"
          >
            前往退款
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="結帳總額"> {{ displayData.totalItemsPrice }} </el-form-item>
      <el-form-item label="實付金額"> {{ displayData.checkoutPrice }} </el-form-item>
      <el-form-item label="付款方式"> {{ displayData.paidType }} </el-form-item>
    </el-form>

    <MarkRefundDialog
      v-if="modal.refund"
      :orderData="orderData"
      :data="refundData"
      :maxPrice="checkoutPrice || null"
      chargeType="checkout"
      @marked="$emit('refresh')"
      @close="modal.refund = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import { get } from 'lodash'
import Tag from '@/components/Tag/Tag.vue'
import { paymentStatusConfig, paymentOptionsConfig } from '@/config/payment'
import MarkRefundDialog from './MarkRefundDialog.vue'

export default defineComponent({
  name: 'CheckoutBlock',
  components: { Tag, MarkRefundDialog },
  props: {
    reservation: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const modal = reactive({
      refund: false,
    })

    const orderData = computed(() => {
      return get(props.reservation, 'AppointmentOrder') || {}
    })

    const checkoutPrice = computed(() => {
      return get(props.reservation, 'AppointmentOrder.AppointmentCheckoutOrder.totalPrice')
    })

    const displayData = computed(() => {
      const status = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.status',
      )
      const checkoutPrice = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.totalPrice',
      )
      const totalItemsPrice = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.totalItemsPrice',
      )

      let displayPaidType
      displayPaidType =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        ) ||
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        )

      if (displayPaidType === 'offline') {
        displayPaidType =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
          ) ||
          get(
            props.reservation,
            'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
          )
      }

      return {
        status: get(paymentStatusConfig, status) || { name: '-', tagType: 'info' },
        checkoutPrice: checkoutPrice ? `$ ${checkoutPrice}` : '-',
        totalItemsPrice: totalItemsPrice ? `$ ${totalItemsPrice}` : '-',
        paidType: displayPaidType === 'free' ? '免付款' : get(paymentOptionsConfig, `${displayPaidType}.name`) || '-',
      }
    })

    const refundData = computed(() => {
      const paidType =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        ) ||
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        )
      const paidTypeComment =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
        ) ||
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
        )
      return {
        refundType: paidType,
        refundTypeComment: paidTypeComment,
      }
    })

    const showRefundBtn = computed(() => {
      const status = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.status',
      )
      return status === 'waitRefund'
    })

    return { displayData, modal, showRefundBtn, refundData, orderData, checkoutPrice }
  },
})
</script>

<style lang="postcss" scoped></style>
